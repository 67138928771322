body::before
{
  display:block;
  content: '';
  height:20px;
}
@media(min-width:768px)
{
  .news-input
  {
    width: 50%;
  } 
}

.card {
  height: 300px
}

/* .card-body {
  display: flex;
  flex-direction: column;
}
.card-link {
   margin-top: auto;
} */

.btn {
  width: 170px
}
